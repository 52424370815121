<template>
  <div class="create-plan-wrapper">
    <div class="header">
      新建计划
    </div>
    <div class="content">
      <a-form ref="valueRef" layout="vertical" :hideRequiredMark="true" :rules="rules" :model="planBody" labelAlign="left">
        <a-form-item label="计划名称" name="name" :labelCol="{span: 23}">
          <a-input style="background: black;"  placeholder="请输入计划名称" v-model:value="planBody.name"/>
        </a-form-item>
        <!-- 设备 -->
        <a-form-item label="执行设备" v-model:value="planBody.dock_sn" name="dock_sn">
          <div style="display: flex;justify-content: center;">
            <router-link :to="{name: 'select-plan'}" @click="selectDevice">选择设备</router-link>
          </div>
        </a-form-item>
        <a-form-item v-if="planBody.dock_sn" style="margin-top: -15px;">
          <!-- <div class="panel" style="padding-top: 5px;" @click="selectDock(dock)"> -->
          <div class="panel" style="padding-top: 5px;">
            <div class="title">
              <a-tooltip :title="dock.nickname">
                <div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{ dock.nickname }}</div>
              </a-tooltip>
            </div>
            <div class="ml10 mt5" style="color: hsla(0,0%,100%,0.65);">
              <span><RocketOutlined /></span>
              <span class="ml5">{{ dock.children?.nickname ?? 'No drone' }}</span>
            </div>
          </div>
        </a-form-item>
        <!-- 航线 -->
        <a-form-item label="执行航线" name="file_id">
          <div style="display: flex;justify-content: center;">
            <router-link :to="{name: 'select-plan'}" @click="selectRoute">选择航线</router-link>
          </div>
        </a-form-item>
        <a-form-item v-if="planBody.file_id" style="margin-top: -15px;">
          <div class="wayline-panel" style="padding-top: 5px;">
            <div class="title">
              <a-tooltip :title="wayline.name">
                <div class="pr10" style="width: 120px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{ wayline.name }}</div>
              </a-tooltip>
              <div class="ml10"><UserOutlined /></div>
              <a-tooltip :title="wayline.user_name">
                <div class="ml5 pr10" style="width: 80px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">{{ wayline.user_name }}</div>
              </a-tooltip>
            </div>
            <div class="ml10 mt5" style="color: hsla(0,0%,100%,0.65);">
              <span><RocketOutlined /></span>
              <!-- <span class="ml5">{{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(wayline.drone_model_key)] }}</span> -->
              <span class="ml5">{{ DEVICE_NAME[wayline.drone_model_key] }}</span>
              <span class="ml10"><CameraFilled style="border-top: 1px solid; padding-top: -3px;" /></span>
              <span class="ml5" v-for="payload in wayline.payload_model_keys" :key="payload.id">
                <!-- {{ Object.keys(EDeviceType)[Object.values(EDeviceType).indexOf(payload)] }} -->
                {{ DEVICE_NAME[payload] }}
              </span>
            </div>
            <div class="mt5 ml10" style="color: hsla(0,0%,100%,0.35);">
              <span class="mr10">更新于 {{ new Date(wayline.update_time).toLocaleString() }}</span>
            </div>
          </div>
        </a-form-item>
        <!-- 任务类型 -->
        <!-- <a-form-item label="任务策略" class="plan-timer-form-item" :labelCol="{span: 23}"> -->
        <a-form-item label="任务精度" class="plan-timer-form-item">
          <div style="white-space: nowrap;">
            <a-radio-group v-model:value="planBody.wayline_precision_type" button-style="solid">
              <a-radio-button style="width: 130px;" :value="1">高精度 RTK</a-radio-button>
              <a-radio-button style="width: 130px;" :value="0"> GNSS </a-radio-button>
            </a-radio-group>
          </div>
        </a-form-item>
        <a-form-item label="任务策略" class="plan-timer-form-item">
          <div style="white-space: nowrap;">
            <a-radio-group v-model:value="planBody.task_type" button-style="solid">
              <a-radio-button v-for="type in TaskTypeOptions" style="width: 86px;" :value="type.value" :key="type.value">{{ type.label }}</a-radio-button>
            </a-radio-group>
          </div>
        </a-form-item>
        <!-- 执行日期 -->
        <a-form-item label="执行日期" v-if="planBody.task_type === TaskType.Timed || planBody.task_type === TaskType.Condition" name="select_execute_date" :labelCol="{span: 23}">
          <a-range-picker v-model:value="planBody.select_execute_date" :disabledDate="(current: Moment) => current < moment().subtract(1, 'days')" />
        </a-form-item>
        <!-- 执行时间 -->
        <a-form-item label="执行时间" v-if="planBody.task_type === TaskType.Timed || planBody.task_type === TaskType.Condition"
          name="select_execute_time" ref="select_execute_time" :labelCol="{span: 23}" :autoLink="false">
          <div class="mb10 flex-row flex-align-center flex-justify-around" v-for="n in planBody.select_time_number" :key="n">
            <a-time-picker
              v-model:value="planBody.select_time[n - 1][0]"
              format="HH:mm:ss"
              show-time
              placeholder="开始时间"
              :style="planBody.task_type === TaskType.Condition ? 'width: 39%' : 'width: 82%'"
              @change="() => $refs.select_execute_time.onFieldChange()"
            />
            <template v-if="planBody.task_type === TaskType.Condition">
              <div><span style="color: white;">-</span></div>
              <a-time-picker
                v-model:value="planBody.select_time[n - 1][1]"
                format="HH:mm:ss"
                show-time
                placeholder="结束时间"
                style="width: 40%;"
              />
            </template>
            <div class="ml5" style="font-size:18px">
              <PlusCircleOutlined class="mr5" style="color: #1890ff" @click="addTime"/>
              <MinusCircleOutlined :style="planBody.select_time_number === 1 ? 'color: gray' : 'color: red;'" @click="removeTime"/>
            </div>
          </div>
        </a-form-item>
        <template v-if="planBody.task_type === TaskType.Condition">
          <!-- battery capacity -->
          <a-form-item label="电池电量达到时启动任务" :labelCol="{span: 23}" name="min_battery_capacity">
            <a-input-number class="width-100" v-model:value="planBody.min_battery_capacity" :min="50" :max="100"
            :formatter="(value: number) => `${value}%`" :parser="(value: string) => value.replace('%', '')">
            </a-input-number>
          </a-form-item>
          <!-- storage capacity -->
          <a-form-item label="当存储级别达到时启动任务 (MB)" :labelCol="{span: 23}" name="storage_capacity">
            <a-input-number v-model:value="planBody.min_storage_capacity" class="width-100">
            </a-input-number>
          </a-form-item>
        </template>
        <!-- RTH Altitude Relative to Dock -->
        <a-form-item label="相对机场返航高度RTH(m)" :labelCol="{span: 23}" name="rth_altitude">
          <a-input-number v-model:value="planBody.rth_altitude" :min="20" :max="1500" class="width-100" required>
          </a-input-number>
        </a-form-item>
        <!-- Lost Action -->
        <a-form-item label="航线飞行中失联" class="plan-timer-form-item" :labelCol="{span: 23}" name="out_of_control_action">
          <div style="white-space: nowrap;">
            <a-radio-group v-model:value="planBody.out_of_control_action" button-style="solid">
              <a-radio-button v-for="action in OutOfControlActionOptions" style="width: 86px;" :value="action.value" :key="action.value">
                {{ action.label }}
              </a-radio-button>
            </a-radio-group>
          </div>
        </a-form-item>
        <a-form-item v-if="false" label="是否在模拟器中执行任务" class="plan-timer-form-item" :labelCol="{span: 19}" name="out_of_control_action">
          <div style="white-space: nowrap;">
            <a-switch v-model:checked="getSimulateMissionChecked" @change="onSimulateMissionChange" />
          </div>
        </a-form-item>
        <a-form-item label="模拟点经度" :labelCol="{span: 23}" name="longitude" v-if="getSimulateMissionChecked">
          <a-input-number v-model:value="planBody.longitude" :min="-180.0" :max="180.0" class="width-100">
          </a-input-number>
        </a-form-item>
        <a-form-item label="模拟点纬度" :labelCol="{span: 23}" name="latitude" v-if="getSimulateMissionChecked">
          <a-input-number v-model:value="planBody.latitude" :min="-90.0" :max="90.0" class="width-100">
          </a-input-number>
        </a-form-item>
        <a-form-item class="width-100" style="margin-bottom: 40px;">
          <div class="footer">
            <a-button class="mr10" style="background: #3c3c3c;" @click="closePlan">取消
            </a-button>
            <a-button type="primary" @click="onSubmit" :disabled="disabled">确定
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </div>
  </div>
  <div v-if="drawerVisible" style="position: absolute; left: 335px; width: 280px; height: 100vh; float: right; top: 0; z-index: 1000; color: white; background: #282828;">
    <div>
      <router-view :name="routeName"/>
    </div>
    <div style="position: absolute; top: 15px; right: 10px;">
      <a style="color: white;" @click="closePanel"><CloseOutlined /></a>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, onMounted, onUnmounted, reactive, ref, toRaw, UnwrapRef } from 'vue'
import { CloseOutlined, RocketOutlined, CameraFilled, UserOutlined, PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons-vue'
import { ELocalStorageKey, ERouterName } from '/@/types'
import { useMyStore } from '/@/store'
import { WaylineType, WaylineFile } from '/@/types/wayline'
import { Device, DEVICE_NAME } from '/@/types/device'
import { createPlan, CreatePlan } from '/@/api/wayline'
import { getRoot } from '/@/root'
import { TaskType, OutOfControlActionOptions, OutOfControlAction, TaskTypeOptions } from '/@/types/task'
import moment, { Moment } from 'moment'
import 'moment/dist/locale/zh-cn'
import { RuleObject } from 'ant-design-vue/es/form/interface'
import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { message } from 'ant-design-vue'

moment.locale('zh-cn')
dayjs.extend(isSameOrBefore)

const root = getRoot()
const store = useMyStore()

const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!

const wayline = computed<WaylineFile>(() => {
  return store.state.waylineInfo
})

const dock = computed<Device>(() => {
  return store.state.dockInfo
})

const disabled = ref(false)

const routeName = ref('')
const planBody = reactive({
  name: '',
  file_id: computed(() => store.state?.waylineInfo.id),
  dock_sn: computed(() => store.state?.dockInfo.device_sn),
  task_type: TaskType.Immediate,
  select_execute_date: [] as Moment[],
  select_execute_time: undefined as Moment[] | undefined,
  select_time_number: 1,
  select_time: [[]] as Moment[][],
  // dom: [] as number[],
  rth_altitude: '100',
  out_of_control_action: OutOfControlAction.ReturnToHome,
  min_battery_capacity: 90 as number,
  min_storage_capacity: undefined as number | undefined,
  break_point: '',
  // simulate_mission模拟飞行
  is_enable: 0,
  longitude: 0,
  latitude: 0,
  wayline_precision_type: 1,
})

const drawerVisible = ref(false)
const valueRef = ref()
const rules = {
  name: [
    { required: true, message: '请输入计划名称.' },
    { max: 20, message: '计划名称长度在1~20字之间' }
  ],
  file_id: [{ required: true, message: '请选择航线' }],
  dock_sn: [{ required: true, message: '请选择设备' }],
  select_execute_date: [{ required: true, message: '请选择执行日期' }],
  select_execute_time: [{
    validator: async (rule: RuleObject, value: Moment[]) => {
      validEndTime()
      validStartTime()
      if (planBody.select_time.length < planBody.select_time_number) {
        throw new Error('请选择时间')
      }
      validOverlapped()
    }
  }],
  rth_altitude: [
    {
      validator: async (rule: RuleObject, value: string) => {
        if (!/^[0-9]{1,}$/.test(value)) {
          // throw new Error('RTH Altitude Relative Require number')
          throw new Error('请输入有效的相对机场返航高度RTH(m)')
        }
      },
    }
  ],
  longitude: [{ required: true, message: '请输入模拟经度' }],
  latitude: [{ required: true, message: '请输入模拟纬度' }],
  min_battery_capacity: [
    {
      validator: async (rule: RuleObject, value: any) => {
        if (TaskType.Condition === planBody.task_type && !value) {
          throw new Error('请输入电量百分比')
        }
      },
    }
  ],
  out_of_control_action: [{ required: true, message: '请选择失联动作' }],
}

function validStartTime (): Error | void {
  for (let i = 0; i < planBody.select_time.length; i++) {
    if (!planBody.select_time[i][0]) {
      throw new Error('请选择开始时间')
    }
  }
}
function validEndTime (): Error | void {
  if (TaskType.Condition !== planBody.task_type) return
  for (let i = 0; i < planBody.select_time.length; i++) {
    if (!planBody.select_time[i][1]) {
      throw new Error('请选择结束时间')
    }
    if (planBody.select_time[i][0] && planBody.select_time[i][1].isSameOrBefore(planBody.select_time[i][0])) {
      throw new Error('结束时间应晚于开始时间')
    }
  }
}
function validOverlapped (): Error | void {
  if (TaskType.Condition !== planBody.task_type) return
  const arr = planBody.select_time.slice()
  arr.sort((a, b) => a[0].unix() - b[0].unix())
  arr.forEach((v, i, arr) => {
    if (i > 0 && v[0] < arr[i - 1][1]) {
      throw new Error('时间重复')
    }
  })
}

const getSimulateMissionChecked = computed(() => {
  return planBody.is_enable === 1
})

function onSimulateMissionChange (val: any) {
  if (val) {
    planBody.is_enable = 1
  } else {
    planBody.is_enable = 0
  }
}

function onSubmit () {
  valueRef.value.validate().then(() => {
    const selectWayline = wayline.value.drone_model_key.split('-')
    if (!(selectWayline.length > 1 && Number(selectWayline[0]) === dock.value.children?.domain && Number(selectWayline[1]) === dock.value.children?.type)) {
      message.error('执行设备与执行航线中设备不一致，请重新选择')
      return
    }
    disabled.value = true
    const createPlanBody = { ...planBody } as unknown as CreatePlan
    if (planBody.select_execute_date.length === 2) {
      createPlanBody.task_days = []
      for (let i = planBody.select_execute_date[0]; i.isSameOrBefore(planBody.select_execute_date[1]);) {
        createPlanBody.task_days.push(i.unix())
        i = i.add(1, 'days')
      }
    }
    createPlanBody.task_periods = []
    if (TaskType.Immediate !== planBody.task_type) {
      for (let i = 0; i < planBody.select_time.length; i++) {
        const result = []
        result.push(planBody.select_time[i][0].unix())
        if (TaskType.Condition === planBody.task_type) {
          result.push(planBody.select_time[i][1].unix())
        }
        createPlanBody.task_periods.push(result)
      }
    }
    createPlanBody.rth_altitude = Number(createPlanBody.rth_altitude)
    if (wayline.value && wayline.value.template_types && wayline.value.template_types.length > 0) {
      createPlanBody.wayline_type = wayline.value.template_types[0]
    }
    localStorage.setItem(createPlanBody.dock_sn, JSON.stringify(createPlanBody))
    createPlan(workspaceId, createPlanBody)
      .then(res => {
        disabled.value = false
      }).finally(() => {
        closePlan()
      })
  }).catch((e: any) => {
    console.log('validate err', e)
  })
}

function closePlan () {
  root.$router.push('/' + ERouterName.TASK)
}

function closePanel () {
  drawerVisible.value = false
  routeName.value = ''
}

function selectRoute () {
  drawerVisible.value = true
  routeName.value = 'WaylinePanel'
}

function selectDevice () {
  drawerVisible.value = true
  routeName.value = 'DockPanel'
}

function addTime () {
  valueRef.value.validateFields(['select_execute_time']).then(() => {
    planBody.select_time_number++
    planBody.select_time.push([])
  })
}
function removeTime () {
  if (planBody.select_time_number === 1) return
  planBody.select_time_number--
  planBody.select_time.splice(planBody.select_time_number)
}
</script>

<style lang="scss">
.dayselect{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  .dayselectitem{
    width: 46px;
    margin: 2px;
    background: #3c3c3c;
  }
}

.create-plan-wrapper {
  background-color: #232323;
  //color: fff;
  padding-bottom: 0;
  height: 84vh;
  display: flex;
  flex-direction: column;
  width: 285px;

  .header {
    height: 52px;
    border-bottom: 1px solid #4f4f4f;
    font-weight: 700;
    font-size: 16px;
    padding-left: 10px;
    display: flex;
    align-items: center;
  }

  .content {
    height: calc(100% - 54px);
    overflow-y: auto;

    form {
      margin: 10px;
    }

    form label, input, .ant-input, .ant-calendar-range-picker-separator,
    .ant-input:hover, .ant-time-picker .anticon, .ant-calendar-picker .anticon {
      background-color: #232323;
      color: #fff;
    }

    .ant-input-suffix {
      color: #fff;
    }

    .plan-timer-form-item {

      .ant-radio-button-wrapper{
        background-color: #232323;
        color: #fff;
        width: 33%;
        text-align: center;
        &.ant-radio-button-wrapper-checked{
          background-color: #1890ff;
        }
      }
    }
  }

  .footer {
    display: flex;
    padding-left: 8px;

    button {
      width: 45%;
      color: #fff ;
      border: 0;
    }
  }
}

.wayline-panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 90px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}

.panel {
  background: #3c3c3c;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  height: 70px;
  width: 95%;
  font-size: 13px;
  border-radius: 2px;
  cursor: pointer;
  .title {
    display: flex;
    color: white;
    flex-direction: row;
    align-items: center;
    height: 30px;
    font-weight: bold;
    margin: 0px 10px 0 10px;
  }
}
</style>
