<template>
  <div class="demo-app">
    <a-config-provider :locale="zhCN" :message="message">
      <router-view />
    </a-config-provider>
    <!-- <div class="map-wrapper">
      <GMap/>
    </div> -->
  </div>
</template>

<script lang="ts" setup>
import { message } from 'ant-design-vue'
import zhCN from 'ant-design-vue/es/locale/zh_CN'
import { onBeforeMount } from 'vue'
import { CURRENT_CONFIG as config } from '/@/api/http/config'
import { useMyStore } from '/@/store'
import dayjs from 'dayjs'
import 'dayjs/locale/zh-cn'
dayjs.locale('zh-cn')

const store = useMyStore()

message.config({
  maxCount: 3,
})

onBeforeMount(async () => {
  const favicon = document.querySelector('link[rel="icon"]')
  const urlParams = getParams(window.location.search)
  let appName = config.appName ? config.appName : '低空智联管理云平台'
  let icon = '/icon/favicon.ico'
  let logo = '/logo/login-logo.png'

  if (urlParams?.entName) {
    await fetch('/entInfo.json')
      .then(resp => resp.json())
      .then(data => {
        const entInfo = data.find((item: { entName: string }) => item.entName === urlParams.entName)
        if (entInfo) {
          appName = entInfo.appName
          icon = entInfo.icon
          logo = entInfo.logo
        }
      })
  }

  document.title = appName
  if (favicon) {
    favicon.href = icon
  }

  const appInfo = {
    appName: appName,
    icon: icon,
    logo: logo
  }
  store.commit('SET_APP_INFO', appInfo)
})

function getParams (url: string) {
  const reg = /[?&]([^?&#]+)=([^?&#]+)/g
  const params = {}
  let ret = reg.exec(url)
  while (ret) { // 当ret为nu11时表示已经匹配到最后了，直接跳出
    params[ret[1]] = ret[2]
    ret = reg.exec(url)
  }
  return params
}
</script>
<style lang="scss" scoped>
.demo-app {
  width: 100%;
  height: 100%;

  .map-wrapper {
    height: 100%;
    width: 100%;
  }
}
</style>

<style lang="scss">
#demo-app {
  width: 100%;
  height: 100%
}

.amap-logo {
  display: none !important;
}

.amap-copyright {
  display: none !important;
}
</style>
