export const CURRENT_CONFIG = {
  appName: '低空智联管理云平台',
  // license
  appId: '152683', // You need to go to the development website to apply.
  appKey: 'af0e6ee7c3248efaa39578d7a498a96', // You need to go to the development website to apply.
  appLicense: 'a1KCEVd/RbPEUM1jst/KFQc24oS8J8BHllpXqEzp+WYQCUXdNO8mtJF++uPe4NDSD05zhyBA2ZHUN4Y7+mUFZubPjfd3XPc6w7O4M9ntETMKX7oPJ6VKs/kX2C/7SsuLoO3NVP47kbRRKbmYOaXZ6ChKgIw8bdPXKztbh8pbDJo=', // You need to go to the development website to apply.

  // http
  // baseURL: 'http://127.0.0.1:6789/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // websocketURL: 'ws://127.0.0.1:6789/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  baseURL: 'http://182.42.58.70/api/',
  websocketURL: 'ws://182.42.58.70/wss/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'
  // baseURL: 'http://192.168.22.88/api/', // This url must end with "/". Example: 'http://192.168.1.1:6789/'
  // websocketURL: 'ws://192.168.22.88/wss/api/v1/ws', // Example: 'ws://192.168.1.1:6789/api/v1/ws'

  // livestreaming
  // RTMP  Note: This IP is the address of the streaming server. If you want to see livestream on web page, you need to convert the RTMP stream to WebRTC stream.
  liveType: 1, // 1 rtmp / 3 gb28181
  rtmpURL: 'rtmp://182.42.58.70:1935/live/', // Example: 'rtmp://123.150.46.202:32006/live/' rtmp://wx.sl.zltt.cn:11935/live/
  rtmpSign: '?sign=24bfc61b7440d8211c33be0c0a6eff21', // Example: 'rtmp://123.150.46.202:32006/live/' rtmp://wx.sl.zltt.cn:11935/live/
  rtcUrl: 'http://182.42.58.70/webrtc/index/api/webrtc?app=live&stream=',
  recordUrl: 'http://182.42.58.70/webrtc/record/',
  qrcodeUrl: 'http://182.42.58.70/share?stream=',
  imageMediaParam: '1440x1080/origin/', // This url must end with "/".
  imagePreviewParam: '250x200/origin/', // This url must end with "/".
  imageIconParam: '40x40/origin/', // This url must end with "/".
  imageURL: 'http://182.42.58.70/preview/unsafe/', // This url must end with "/". 123.150.46.202:32003
  imageResultURL: 'http://182.42.58.70/preview/rx/', // This url must end with "/".
  imageMinioURL: 'http://182.42.58.70/minio/origin/', // This url must end with "/".
  terraMinioURL: 'http://182.42.58.70/minio/map/', // This url must end with "/".
  // GB28181 Note:If you don't know what these parameters mean, you can go to Pilot2 and select the GB28181 page in the cloud platform. Where the parameters same as these parameters.
  gbServerIp: '182.42.58.70',
  gbServerPort: '5060',
  gbServerId: '34020000002000000001',
  gbAgentId: '34020000002000000001',
  gbPassword: 'Zerone@123123',
  gbAgentPort: '5060',
  gbAgentChannel: '34020000002000000001',
  // RTSP
  rtspUserName: 'Please enter the username.',
  rtspPassword: 'Please enter the password.',
  rtspPort: '8554',
  // Agora
  agoraAPPID: 'Please enter the agora app id.',
  agoraToken: 'Please enter the agora temporary token.',
  agoraChannel: 'Please enter the agora channel.',

  // AI
  enableAI: 0, // 0 1
  // ai output
  minioPath: '/mnt/data/minio_data/origin/',
  // Weather
  enableWeather: 1, // 0 1
  // LocalMap
  enableLocalMap: 0, // 0 1
  localMapType: 'WGS84', // WGS84 GCJ02
  localMapUrlCia: 'http://182.42.58.70/minio/map/tmap/tianjin_biaozhu-z0-18.cHybNXgNg/{z}/{x}/{y}.png', // 标注图层
  localMapUrlImg: 'http://182.42.58.70/minio/map/tmap/tianjin_yingxiang-z0-18.eS2dvugNR/{z}/{x}/{y}.png', // 影像图层
  localMapUrlTerrain: 'http://182.42.58.70/minio/map/terrain/tianjin', // 地形数据
  // amap
  // You can apply on the AMap website.
  amapKey: 'e88a8a84a8907604a139e45cb931f686',
  // 天地图Key
  tiandituKey: '436ce7e50d27eede2f2929307e6b33c0',
  // 行政区域
  area:
  // 国家
  // '/Cesium/GeoJson/china.json',
  // 省份
  // '/Cesium/GeoJson/geometryProvince/11.json',
  '/Cesium/GeoJson/geometryProvince/12.json',
  // '/Cesium/GeoJson/geometryProvince/13.json',
  // '/Cesium/GeoJson/geometryProvince/14.json',
  // '/Cesium/GeoJson/geometryProvince/15.json',
  // '/Cesium/GeoJson/geometryProvince/21.json',
  // '/Cesium/GeoJson/geometryProvince/22.json',
  // '/Cesium/GeoJson/geometryProvince/23.json',
  // '/Cesium/GeoJson/geometryProvince/31.json',
  // '/Cesium/GeoJson/geometryProvince/32.json',
  // '/Cesium/GeoJson/geometryProvince/33.json',
  // '/Cesium/GeoJson/geometryProvince/34.json',
  // '/Cesium/GeoJson/geometryProvince/35.json',
  // '/Cesium/GeoJson/geometryProvince/36.json',
  // '/Cesium/GeoJson/geometryProvince/37.json',
  // '/Cesium/GeoJson/geometryProvince/41.json',
  // '/Cesium/GeoJson/geometryProvince/42.json',
  // '/Cesium/GeoJson/geometryProvince/43.json',
  // '/Cesium/GeoJson/geometryProvince/44.json',
  // '/Cesium/GeoJson/geometryProvince/45.json',
  // '/Cesium/GeoJson/geometryProvince/46.json',
  // '/Cesium/GeoJson/geometryProvince/50.json',
  // '/Cesium/GeoJson/geometryProvince/51.json',
  // '/Cesium/GeoJson/geometryProvince/52.json',
  // '/Cesium/GeoJson/geometryProvince/53.json',
  // '/Cesium/GeoJson/geometryProvince/54.json',
  // '/Cesium/GeoJson/geometryProvince/61.json',
  // '/Cesium/GeoJson/geometryProvince/62.json',
  // '/Cesium/GeoJson/geometryProvince/63.json',
  // '/Cesium/GeoJson/geometryProvince/64.json',
  // '/Cesium/GeoJson/geometryProvince/65.json',
  // '/Cesium/GeoJson/geometryProvince/71.json',
  // '/Cesium/GeoJson/geometryProvince/81.json',
  // '/Cesium/GeoJson/geometryProvince/82.json',
  // 城市
  // '/Cesium/GeoJson/geometryCouties/110100.json',
  // '/Cesium/GeoJson/geometryCouties/120100.json',
  // '/Cesium/GeoJson/geometryCouties/130100.json',
  // '/Cesium/GeoJson/geometryCouties/130200.json',
  // '/Cesium/GeoJson/geometryCouties/130300.json',
  // '/Cesium/GeoJson/geometryCouties/130400.json',
  // '/Cesium/GeoJson/geometryCouties/130500.json',
  // '/Cesium/GeoJson/geometryCouties/130600.json',
  // '/Cesium/GeoJson/geometryCouties/130700.json',
  // '/Cesium/GeoJson/geometryCouties/130800.json',
  // '/Cesium/GeoJson/geometryCouties/130900.json',
  // '/Cesium/GeoJson/geometryCouties/131000.json',
  // '/Cesium/GeoJson/geometryCouties/131100.json',
  // '/Cesium/GeoJson/geometryCouties/140100.json',
  // '/Cesium/GeoJson/geometryCouties/140200.json',
  // '/Cesium/GeoJson/geometryCouties/140300.json',
  // '/Cesium/GeoJson/geometryCouties/140400.json',
  // '/Cesium/GeoJson/geometryCouties/140500.json',
  // '/Cesium/GeoJson/geometryCouties/140600.json',
  // '/Cesium/GeoJson/geometryCouties/140700.json',
  // '/Cesium/GeoJson/geometryCouties/140800.json',
  // '/Cesium/GeoJson/geometryCouties/140900.json',
  // '/Cesium/GeoJson/geometryCouties/141000.json',
  // '/Cesium/GeoJson/geometryCouties/141100.json',
  // '/Cesium/GeoJson/geometryCouties/150100.json',
  // '/Cesium/GeoJson/geometryCouties/150200.json',
  // '/Cesium/GeoJson/geometryCouties/150300.json',
  // '/Cesium/GeoJson/geometryCouties/150400.json',
  // '/Cesium/GeoJson/geometryCouties/150500.json',
  // '/Cesium/GeoJson/geometryCouties/150600.json',
  // '/Cesium/GeoJson/geometryCouties/150700.json',
  // '/Cesium/GeoJson/geometryCouties/150800.json',
  // '/Cesium/GeoJson/geometryCouties/150900.json',
  // '/Cesium/GeoJson/geometryCouties/152200.json',
  // '/Cesium/GeoJson/geometryCouties/152500.json',
  // '/Cesium/GeoJson/geometryCouties/152900.json',
  // '/Cesium/GeoJson/geometryCouties/210100.json',
  // '/Cesium/GeoJson/geometryCouties/210200.json',
  // '/Cesium/GeoJson/geometryCouties/210300.json',
  // '/Cesium/GeoJson/geometryCouties/210400.json',
  // '/Cesium/GeoJson/geometryCouties/210500.json',
  // '/Cesium/GeoJson/geometryCouties/210600.json',
  // '/Cesium/GeoJson/geometryCouties/210700.json',
  // '/Cesium/GeoJson/geometryCouties/210800.json',
  // '/Cesium/GeoJson/geometryCouties/210900.json',
  // '/Cesium/GeoJson/geometryCouties/211000.json',
  // '/Cesium/GeoJson/geometryCouties/211100.json',
  // '/Cesium/GeoJson/geometryCouties/211200.json',
  // '/Cesium/GeoJson/geometryCouties/211300.json',
  // '/Cesium/GeoJson/geometryCouties/211400.json',
  // '/Cesium/GeoJson/geometryCouties/220100.json',
  // '/Cesium/GeoJson/geometryCouties/220200.json',
  // '/Cesium/GeoJson/geometryCouties/220300.json',
  // '/Cesium/GeoJson/geometryCouties/220400.json',
  // '/Cesium/GeoJson/geometryCouties/220500.json',
  // '/Cesium/GeoJson/geometryCouties/220600.json',
  // '/Cesium/GeoJson/geometryCouties/220700.json',
  // '/Cesium/GeoJson/geometryCouties/220800.json',
  // '/Cesium/GeoJson/geometryCouties/222400.json',
  // '/Cesium/GeoJson/geometryCouties/230100.json',
  // '/Cesium/GeoJson/geometryCouties/230200.json',
  // '/Cesium/GeoJson/geometryCouties/230300.json',
  // '/Cesium/GeoJson/geometryCouties/230400.json',
  // '/Cesium/GeoJson/geometryCouties/230500.json',
  // '/Cesium/GeoJson/geometryCouties/230600.json',
  // '/Cesium/GeoJson/geometryCouties/230700.json',
  // '/Cesium/GeoJson/geometryCouties/230800.json',
  // '/Cesium/GeoJson/geometryCouties/230900.json',
  // '/Cesium/GeoJson/geometryCouties/231000.json',
  // '/Cesium/GeoJson/geometryCouties/231100.json',
  // '/Cesium/GeoJson/geometryCouties/231200.json',
  // '/Cesium/GeoJson/geometryCouties/232700.json',
  // '/Cesium/GeoJson/geometryCouties/310100.json',
  // '/Cesium/GeoJson/geometryCouties/310200.json',
  // '/Cesium/GeoJson/geometryCouties/320100.json',
  // '/Cesium/GeoJson/geometryCouties/320200.json',
  // '/Cesium/GeoJson/geometryCouties/320300.json',
  // '/Cesium/GeoJson/geometryCouties/320400.json',
  // '/Cesium/GeoJson/geometryCouties/320500.json',
  // '/Cesium/GeoJson/geometryCouties/320600.json',
  // '/Cesium/GeoJson/geometryCouties/320700.json',
  // '/Cesium/GeoJson/geometryCouties/320800.json',
  // '/Cesium/GeoJson/geometryCouties/320900.json',
  // '/Cesium/GeoJson/geometryCouties/321000.json',
  // '/Cesium/GeoJson/geometryCouties/321100.json',
  // '/Cesium/GeoJson/geometryCouties/321200.json',
  // '/Cesium/GeoJson/geometryCouties/321300.json',
  // '/Cesium/GeoJson/geometryCouties/330100.json',
  // '/Cesium/GeoJson/geometryCouties/330200.json',
  // '/Cesium/GeoJson/geometryCouties/330300.json',
  // '/Cesium/GeoJson/geometryCouties/330400.json',
  // '/Cesium/GeoJson/geometryCouties/330500.json',
  // '/Cesium/GeoJson/geometryCouties/330600.json',
  // '/Cesium/GeoJson/geometryCouties/330700.json',
  // '/Cesium/GeoJson/geometryCouties/330800.json',
  // '/Cesium/GeoJson/geometryCouties/330900.json',
  // '/Cesium/GeoJson/geometryCouties/331000.json',
  // '/Cesium/GeoJson/geometryCouties/331100.json',
  // '/Cesium/GeoJson/geometryCouties/340100.json',
  // '/Cesium/GeoJson/geometryCouties/340200.json',
  // '/Cesium/GeoJson/geometryCouties/340300.json',
  // '/Cesium/GeoJson/geometryCouties/340400.json',
  // '/Cesium/GeoJson/geometryCouties/340500.json',
  // '/Cesium/GeoJson/geometryCouties/340600.json',
  // '/Cesium/GeoJson/geometryCouties/340700.json',
  // '/Cesium/GeoJson/geometryCouties/340800.json',
  // '/Cesium/GeoJson/geometryCouties/341000.json',
  // '/Cesium/GeoJson/geometryCouties/341100.json',
  // '/Cesium/GeoJson/geometryCouties/341200.json',
  // '/Cesium/GeoJson/geometryCouties/341300.json',
  // '/Cesium/GeoJson/geometryCouties/341500.json',
  // '/Cesium/GeoJson/geometryCouties/341600.json',
  // '/Cesium/GeoJson/geometryCouties/341700.json',
  // '/Cesium/GeoJson/geometryCouties/341800.json',
  // '/Cesium/GeoJson/geometryCouties/350100.json',
  // '/Cesium/GeoJson/geometryCouties/350200.json',
  // '/Cesium/GeoJson/geometryCouties/350300.json',
  // '/Cesium/GeoJson/geometryCouties/350400.json',
  // '/Cesium/GeoJson/geometryCouties/350500.json',
  // '/Cesium/GeoJson/geometryCouties/350600.json',
  // '/Cesium/GeoJson/geometryCouties/350700.json',
  // '/Cesium/GeoJson/geometryCouties/350800.json',
  // '/Cesium/GeoJson/geometryCouties/350900.json',
  // '/Cesium/GeoJson/geometryCouties/360100.json',
  // '/Cesium/GeoJson/geometryCouties/360200.json',
  // '/Cesium/GeoJson/geometryCouties/360300.json',
  // '/Cesium/GeoJson/geometryCouties/360400.json',
  // '/Cesium/GeoJson/geometryCouties/360500.json',
  // '/Cesium/GeoJson/geometryCouties/360600.json',
  // '/Cesium/GeoJson/geometryCouties/360700.json',
  // '/Cesium/GeoJson/geometryCouties/360800.json',
  // '/Cesium/GeoJson/geometryCouties/360900.json',
  // '/Cesium/GeoJson/geometryCouties/361000.json',
  // '/Cesium/GeoJson/geometryCouties/361100.json',
  // '/Cesium/GeoJson/geometryCouties/370100.json',
  // '/Cesium/GeoJson/geometryCouties/370200.json',
  // '/Cesium/GeoJson/geometryCouties/370300.json',
  // '/Cesium/GeoJson/geometryCouties/370400.json',
  // '/Cesium/GeoJson/geometryCouties/370500.json',
  // '/Cesium/GeoJson/geometryCouties/370600.json',
  // '/Cesium/GeoJson/geometryCouties/370700.json',
  // '/Cesium/GeoJson/geometryCouties/370800.json',
  // '/Cesium/GeoJson/geometryCouties/370900.json',
  // '/Cesium/GeoJson/geometryCouties/371000.json',
  // '/Cesium/GeoJson/geometryCouties/371100.json',
  // '/Cesium/GeoJson/geometryCouties/371200.json',
  // '/Cesium/GeoJson/geometryCouties/371300.json',
  // '/Cesium/GeoJson/geometryCouties/371400.json',
  // '/Cesium/GeoJson/geometryCouties/371500.json',
  // '/Cesium/GeoJson/geometryCouties/371600.json',
  // '/Cesium/GeoJson/geometryCouties/371700.json',
  // '/Cesium/GeoJson/geometryCouties/410100.json',
  // '/Cesium/GeoJson/geometryCouties/410200.json',
  // '/Cesium/GeoJson/geometryCouties/410300.json',
  // '/Cesium/GeoJson/geometryCouties/410400.json',
  // '/Cesium/GeoJson/geometryCouties/410500.json',
  // '/Cesium/GeoJson/geometryCouties/410600.json',
  // '/Cesium/GeoJson/geometryCouties/410700.json',
  // '/Cesium/GeoJson/geometryCouties/410800.json',
  // '/Cesium/GeoJson/geometryCouties/410900.json',
  // '/Cesium/GeoJson/geometryCouties/411000.json',
  // '/Cesium/GeoJson/geometryCouties/411100.json',
  // '/Cesium/GeoJson/geometryCouties/411200.json',
  // '/Cesium/GeoJson/geometryCouties/411300.json',
  // '/Cesium/GeoJson/geometryCouties/411400.json',
  // '/Cesium/GeoJson/geometryCouties/411500.json',
  // '/Cesium/GeoJson/geometryCouties/411600.json',
  // '/Cesium/GeoJson/geometryCouties/411700.json',
  // '/Cesium/GeoJson/geometryCouties/420100.json',
  // '/Cesium/GeoJson/geometryCouties/420200.json',
  // '/Cesium/GeoJson/geometryCouties/420300.json',
  // '/Cesium/GeoJson/geometryCouties/420500.json',
  // '/Cesium/GeoJson/geometryCouties/420600.json',
  // '/Cesium/GeoJson/geometryCouties/420700.json',
  // '/Cesium/GeoJson/geometryCouties/420800.json',
  // '/Cesium/GeoJson/geometryCouties/420900.json',
  // '/Cesium/GeoJson/geometryCouties/421000.json',
  // '/Cesium/GeoJson/geometryCouties/421100.json',
  // '/Cesium/GeoJson/geometryCouties/421200.json',
  // '/Cesium/GeoJson/geometryCouties/421300.json',
  // '/Cesium/GeoJson/geometryCouties/422800.json',
  // '/Cesium/GeoJson/geometryCouties/429000.json',
  // '/Cesium/GeoJson/geometryCouties/430100.json',
  // '/Cesium/GeoJson/geometryCouties/430200.json',
  // '/Cesium/GeoJson/geometryCouties/430300.json',
  // '/Cesium/GeoJson/geometryCouties/430400.json',
  // '/Cesium/GeoJson/geometryCouties/430500.json',
  // '/Cesium/GeoJson/geometryCouties/430600.json',
  // '/Cesium/GeoJson/geometryCouties/430700.json',
  // '/Cesium/GeoJson/geometryCouties/430800.json',
  // '/Cesium/GeoJson/geometryCouties/430900.json',
  // '/Cesium/GeoJson/geometryCouties/431000.json',
  // '/Cesium/GeoJson/geometryCouties/431100.json',
  // '/Cesium/GeoJson/geometryCouties/431200.json',
  // '/Cesium/GeoJson/geometryCouties/431300.json',
  // '/Cesium/GeoJson/geometryCouties/433100.json',
  // '/Cesium/GeoJson/geometryCouties/440100.json',
  // '/Cesium/GeoJson/geometryCouties/440200.json',
  // '/Cesium/GeoJson/geometryCouties/440300.json',
  // '/Cesium/GeoJson/geometryCouties/440400.json',
  // '/Cesium/GeoJson/geometryCouties/440500.json',
  // '/Cesium/GeoJson/geometryCouties/440600.json',
  // '/Cesium/GeoJson/geometryCouties/440700.json',
  // '/Cesium/GeoJson/geometryCouties/440800.json',
  // '/Cesium/GeoJson/geometryCouties/440900.json',
  // '/Cesium/GeoJson/geometryCouties/441200.json',
  // '/Cesium/GeoJson/geometryCouties/441300.json',
  // '/Cesium/GeoJson/geometryCouties/441400.json',
  // '/Cesium/GeoJson/geometryCouties/441500.json',
  // '/Cesium/GeoJson/geometryCouties/441600.json',
  // '/Cesium/GeoJson/geometryCouties/441700.json',
  // '/Cesium/GeoJson/geometryCouties/441800.json',
  // '/Cesium/GeoJson/geometryCouties/441900.json',
  // '/Cesium/GeoJson/geometryCouties/442000.json',
  // '/Cesium/GeoJson/geometryCouties/445100.json',
  // '/Cesium/GeoJson/geometryCouties/445200.json',
  // '/Cesium/GeoJson/geometryCouties/445300.json',
  // '/Cesium/GeoJson/geometryCouties/450100.json',
  // '/Cesium/GeoJson/geometryCouties/450200.json',
  // '/Cesium/GeoJson/geometryCouties/450300.json',
  // '/Cesium/GeoJson/geometryCouties/450400.json',
  // '/Cesium/GeoJson/geometryCouties/450500.json',
  // '/Cesium/GeoJson/geometryCouties/450600.json',
  // '/Cesium/GeoJson/geometryCouties/450700.json',
  // '/Cesium/GeoJson/geometryCouties/450800.json',
  // '/Cesium/GeoJson/geometryCouties/450900.json',
  // '/Cesium/GeoJson/geometryCouties/451000.json',
  // '/Cesium/GeoJson/geometryCouties/451100.json',
  // '/Cesium/GeoJson/geometryCouties/451200.json',
  // '/Cesium/GeoJson/geometryCouties/451300.json',
  // '/Cesium/GeoJson/geometryCouties/451400.json',
  // '/Cesium/GeoJson/geometryCouties/460100.json',
  // '/Cesium/GeoJson/geometryCouties/460200.json',
  // '/Cesium/GeoJson/geometryCouties/460300.json',
  // '/Cesium/GeoJson/geometryCouties/469000.json',
  // '/Cesium/GeoJson/geometryCouties/500100.json',
  // '/Cesium/GeoJson/geometryCouties/510100.json',
  // '/Cesium/GeoJson/geometryCouties/510300.json',
  // '/Cesium/GeoJson/geometryCouties/510400.json',
  // '/Cesium/GeoJson/geometryCouties/510500.json',
  // '/Cesium/GeoJson/geometryCouties/510600.json',
  // '/Cesium/GeoJson/geometryCouties/510700.json',
  // '/Cesium/GeoJson/geometryCouties/510800.json',
  // '/Cesium/GeoJson/geometryCouties/510900.json',
  // '/Cesium/GeoJson/geometryCouties/511000.json',
  // '/Cesium/GeoJson/geometryCouties/511100.json',
  // '/Cesium/GeoJson/geometryCouties/511300.json',
  // '/Cesium/GeoJson/geometryCouties/511400.json',
  // '/Cesium/GeoJson/geometryCouties/511500.json',
  // '/Cesium/GeoJson/geometryCouties/511600.json',
  // '/Cesium/GeoJson/geometryCouties/511700.json',
  // '/Cesium/GeoJson/geometryCouties/511800.json',
  // '/Cesium/GeoJson/geometryCouties/511900.json',
  // '/Cesium/GeoJson/geometryCouties/512000.json',
  // '/Cesium/GeoJson/geometryCouties/513200.json',
  // '/Cesium/GeoJson/geometryCouties/513300.json',
  // '/Cesium/GeoJson/geometryCouties/513400.json',
  // '/Cesium/GeoJson/geometryCouties/520100.json',
  // '/Cesium/GeoJson/geometryCouties/520200.json',
  // '/Cesium/GeoJson/geometryCouties/520300.json',
  // '/Cesium/GeoJson/geometryCouties/520400.json',
  // '/Cesium/GeoJson/geometryCouties/522200.json',
  // '/Cesium/GeoJson/geometryCouties/522300.json',
  // '/Cesium/GeoJson/geometryCouties/522400.json',
  // '/Cesium/GeoJson/geometryCouties/522600.json',
  // '/Cesium/GeoJson/geometryCouties/522700.json',
  // '/Cesium/GeoJson/geometryCouties/530100.json',
  // '/Cesium/GeoJson/geometryCouties/530300.json',
  // '/Cesium/GeoJson/geometryCouties/530400.json',
  // '/Cesium/GeoJson/geometryCouties/530500.json',
  // '/Cesium/GeoJson/geometryCouties/530600.json',
  // '/Cesium/GeoJson/geometryCouties/530700.json',
  // '/Cesium/GeoJson/geometryCouties/530800.json',
  // '/Cesium/GeoJson/geometryCouties/530900.json',
  // '/Cesium/GeoJson/geometryCouties/532300.json',
  // '/Cesium/GeoJson/geometryCouties/532500.json',
  // '/Cesium/GeoJson/geometryCouties/532600.json',
  // '/Cesium/GeoJson/geometryCouties/532800.json',
  // '/Cesium/GeoJson/geometryCouties/532900.json',
  // '/Cesium/GeoJson/geometryCouties/533100.json',
  // '/Cesium/GeoJson/geometryCouties/533300.json',
  // '/Cesium/GeoJson/geometryCouties/533400.json',
  // '/Cesium/GeoJson/geometryCouties/540100.json',
  // '/Cesium/GeoJson/geometryCouties/542100.json',
  // '/Cesium/GeoJson/geometryCouties/542200.json',
  // '/Cesium/GeoJson/geometryCouties/542300.json',
  // '/Cesium/GeoJson/geometryCouties/542400.json',
  // '/Cesium/GeoJson/geometryCouties/542500.json',
  // '/Cesium/GeoJson/geometryCouties/542600.json',
  // '/Cesium/GeoJson/geometryCouties/610100.json',
  // '/Cesium/GeoJson/geometryCouties/610200.json',
  // '/Cesium/GeoJson/geometryCouties/610300.json',
  // '/Cesium/GeoJson/geometryCouties/610400.json',
  // '/Cesium/GeoJson/geometryCouties/610500.json',
  // '/Cesium/GeoJson/geometryCouties/610600.json',
  // '/Cesium/GeoJson/geometryCouties/610700.json',
  // '/Cesium/GeoJson/geometryCouties/610800.json',
  // '/Cesium/GeoJson/geometryCouties/610900.json',
  // '/Cesium/GeoJson/geometryCouties/611000.json',
  // '/Cesium/GeoJson/geometryCouties/620100.json',
  // '/Cesium/GeoJson/geometryCouties/620200.json',
  // '/Cesium/GeoJson/geometryCouties/620300.json',
  // '/Cesium/GeoJson/geometryCouties/620400.json',
  // '/Cesium/GeoJson/geometryCouties/620500.json',
  // '/Cesium/GeoJson/geometryCouties/620600.json',
  // '/Cesium/GeoJson/geometryCouties/620700.json',
  // '/Cesium/GeoJson/geometryCouties/620800.json',
  // '/Cesium/GeoJson/geometryCouties/620900.json',
  // '/Cesium/GeoJson/geometryCouties/621000.json',
  // '/Cesium/GeoJson/geometryCouties/621100.json',
  // '/Cesium/GeoJson/geometryCouties/621200.json',
  // '/Cesium/GeoJson/geometryCouties/622900.json',
  // '/Cesium/GeoJson/geometryCouties/623000.json',
  // '/Cesium/GeoJson/geometryCouties/630100.json',
  // '/Cesium/GeoJson/geometryCouties/632100.json',
  // '/Cesium/GeoJson/geometryCouties/632200.json',
  // '/Cesium/GeoJson/geometryCouties/632300.json',
  // '/Cesium/GeoJson/geometryCouties/632500.json',
  // '/Cesium/GeoJson/geometryCouties/632600.json',
  // '/Cesium/GeoJson/geometryCouties/632700.json',
  // '/Cesium/GeoJson/geometryCouties/632800.json',
  // '/Cesium/GeoJson/geometryCouties/640100.json',
  // '/Cesium/GeoJson/geometryCouties/640200.json',
  // '/Cesium/GeoJson/geometryCouties/640300.json',
  // '/Cesium/GeoJson/geometryCouties/640400.json',
  // '/Cesium/GeoJson/geometryCouties/640500.json',
  // '/Cesium/GeoJson/geometryCouties/650100.json',
  // '/Cesium/GeoJson/geometryCouties/650200.json',
  // '/Cesium/GeoJson/geometryCouties/652100.json',
  // '/Cesium/GeoJson/geometryCouties/652200.json',
  // '/Cesium/GeoJson/geometryCouties/652300.json',
  // '/Cesium/GeoJson/geometryCouties/652700.json',
  // '/Cesium/GeoJson/geometryCouties/652800.json',
  // '/Cesium/GeoJson/geometryCouties/652900.json',
  // '/Cesium/GeoJson/geometryCouties/653000.json',
  // '/Cesium/GeoJson/geometryCouties/653100.json',
  // '/Cesium/GeoJson/geometryCouties/653200.json',
  // '/Cesium/GeoJson/geometryCouties/654000.json',
  // '/Cesium/GeoJson/geometryCouties/654200.json',
  // '/Cesium/GeoJson/geometryCouties/654300.json',
  // '/Cesium/GeoJson/geometryCouties/659000.json',
  // '/Cesium/GeoJson/geometryCouties/710000.json',
  // '/Cesium/GeoJson/geometryCouties/810100.json',
  // '/Cesium/GeoJson/geometryCouties/820000.json',

  // 禁飞区
  noFlyZone: [
    // '/Cesium/NoFlyZone/白山_长白山机场.geojson',
    // '/Cesium/NoFlyZone/百色_巴马机场.geojson',
    // '/Cesium/NoFlyZone/包头机场.geojson',
    // '/Cesium/NoFlyZone/保山机场.geojson',
    // '/Cesium/NoFlyZone/北海_福成机场.geojson',
    // '/Cesium/NoFlyZone/北京_首都机场.geojson',
    // '/Cesium/NoFlyZone/博乐_阿拉山口机场.geojson',
    // '/Cesium/NoFlyZone/布尔津_喀纳斯机场.geojson',
    // '/Cesium/NoFlyZone/常德_桃花源机场.geojson',
    // '/Cesium/NoFlyZone/常州_奔牛机场.geojson',
    // '/Cesium/NoFlyZone/朝阳机场.geojson',
    // '/Cesium/NoFlyZone/成都_双流机场.geojson',
    // '/Cesium/NoFlyZone/池州_九华山机场.geojson',
    // '/Cesium/NoFlyZone/赤峰_玉龙机场.geojson',
    // '/Cesium/NoFlyZone/达州_河市机场.geojson',
    // '/Cesium/NoFlyZone/大连_周水子机场.geojson',
    // '/Cesium/NoFlyZone/大庆_萨尔图机场.geojson',
    // '/Cesium/NoFlyZone/大同_云冈机场.geojson',
    // '/Cesium/NoFlyZone/丹东_浪头机场.geojson',
    // '/Cesium/NoFlyZone/稻城_亚丁机场.geojson',
    // '/Cesium/NoFlyZone/德宏_芒市机场.geojson',
    // '/Cesium/NoFlyZone/东营机场.geojson',
    // '/Cesium/NoFlyZone/敦煌机场.geojson',
    // '/Cesium/NoFlyZone/鄂尔多斯_伊金霍洛机场.geojson',
    // '/Cesium/NoFlyZone/恩施_许家坪机场.geojson',
    // '/Cesium/NoFlyZone/二连浩特_赛乌素机场.geojson',
    // '/Cesium/NoFlyZone/福州_长乐机场.geojson',
    // '/Cesium/NoFlyZone/抚远_东极机场.geojson',
    // '/Cesium/NoFlyZone/富蕴_可可托海机场.geojson',
    // '/Cesium/NoFlyZone/甘南_夏河机场.geojson',
    // '/Cesium/NoFlyZone/赣州_黄金机场.geojson',
    // '/Cesium/NoFlyZone/固原_六盘山机场.geojson',
    // '/Cesium/NoFlyZone/广元_盘龙机场.geojson',
    // '/Cesium/NoFlyZone/广州_白云机场.geojson',
    // '/Cesium/NoFlyZone/贵阳_龙洞堡机场.geojson',
    // '/Cesium/NoFlyZone/桂林_两江机场.geojson',
    // '/Cesium/NoFlyZone/果洛_玛沁机场.geojson',
    // '/Cesium/NoFlyZone/哈尔滨_太平机场.geojson',
    // '/Cesium/NoFlyZone/海口_美兰机场.geojson',
    // '/Cesium/NoFlyZone/海西_德令哈机场.geojson',
    // '/Cesium/NoFlyZone/海西_花土沟机场.geojson',
    // '/Cesium/NoFlyZone/汉中_城固机场.geojson',
    // '/Cesium/NoFlyZone/杭州_萧山机场.geojson',
    // '/Cesium/NoFlyZone/合肥_新桥机场.geojson',
    // '/Cesium/NoFlyZone/和田机场.geojson',
    // '/Cesium/NoFlyZone/河池_金城江机场.geojson',
    // '/Cesium/NoFlyZone/黑河_瑷珲机场.geojson',
    // '/Cesium/NoFlyZone/衡阳_南岳机场.geojson',
    // '/Cesium/NoFlyZone/呼和浩特_白塔机场.geojson',
    // '/Cesium/NoFlyZone/呼伦贝尔_海拉尔机场.geojson',
    // '/Cesium/NoFlyZone/怀化_芷江机场.geojson',
    // '/Cesium/NoFlyZone/淮安_涟水机场.geojson',
    // '/Cesium/NoFlyZone/黄山_屯溪机场.geojson',
    // '/Cesium/NoFlyZone/鸡西_兴凯湖机场.geojson',
    // '/Cesium/NoFlyZone/济南_遥墙机场.geojson',
    // '/Cesium/NoFlyZone/济宁_曲阜机场.geojson',
    // '/Cesium/NoFlyZone/加格达奇_嘎仙机场.geojson',
    // '/Cesium/NoFlyZone/佳木斯机场.geojson',
    // '/Cesium/NoFlyZone/嘉峪关机场.geojson',
    // '/Cesium/NoFlyZone/揭阳_潮汕机场.geojson',
    // '/Cesium/NoFlyZone/金昌_金川机场.geojson',
    // '/Cesium/NoFlyZone/锦州_锦州湾机场.geojson',
    // '/Cesium/NoFlyZone/井冈山机场.geojson',
    // '/Cesium/NoFlyZone/景德镇_罗家机场.geojson',
    // '/Cesium/NoFlyZone/喀什机场.geojson',
    // '/Cesium/NoFlyZone/康定机场.geojson',
    // '/Cesium/NoFlyZone/克拉玛依机场.geojson',
    // '/Cesium/NoFlyZone/库车_龟兹机场.geojson',
    // '/Cesium/NoFlyZone/昆明_长水机场.geojson',
    // '/Cesium/NoFlyZone/兰州_中川机场.geojson',
    // '/Cesium/NoFlyZone/澜沧_景迈机场.geojson',
    // '/Cesium/NoFlyZone/黎平机场.geojson',
    // '/Cesium/NoFlyZone/丽江_三义机场.geojson',
    // '/Cesium/NoFlyZone/连云港_白塔埠机场.geojson',
    // '/Cesium/NoFlyZone/临沧_博尚机场.geojson',
    // '/Cesium/NoFlyZone/临汾_乔李机场.geojson',
    // '/Cesium/NoFlyZone/临沂_沭埠岭机场.geojson',
    // '/Cesium/NoFlyZone/柳州_白莲机场.geojson',
    // '/Cesium/NoFlyZone/洛阳机场.geojson',
    // '/Cesium/NoFlyZone/满洲里_西郊机场.geojson',
    // '/Cesium/NoFlyZone/梅县_长岗岌机场.geojson',
    // '/Cesium/NoFlyZone/漠河_古莲机场.geojson',
    // '/Cesium/NoFlyZone/牡丹江_海浪机场.geojson',
    // '/Cesium/NoFlyZone/南昌_昌北机场.geojson',
    // '/Cesium/NoFlyZone/南京_禄口机场.geojson',
    // '/Cesium/NoFlyZone/南宁_吴圩机场.geojson',
    // '/Cesium/NoFlyZone/南通_兴东机场.geojson',
    // '/Cesium/NoFlyZone/南阳_姜营机场.geojson',
    // '/Cesium/NoFlyZone/宁波_栎社机场.geojson',
    // '/Cesium/NoFlyZone/宁蒗_泸沽湖机场.geojson',
    // '/Cesium/NoFlyZone/攀枝花_保安营机场.geojson',
    // '/Cesium/NoFlyZone/普洱_思茅机场.geojson',
    // '/Cesium/NoFlyZone/齐齐哈尔_三家子机场.geojson',
    // '/Cesium/NoFlyZone/黔江_武陵山机场.geojson',
    // '/Cesium/NoFlyZone/且末_玉都机场.geojson',
    // '/Cesium/NoFlyZone/秦皇岛_北戴河机场.geojson',
    // '/Cesium/NoFlyZone/青岛_流亭机场.geojson',
    // '/Cesium/NoFlyZone/庆阳_西峰机场.geojson',
    // '/Cesium/NoFlyZone/琼海_博鳌机场.geojson',
    // '/Cesium/NoFlyZone/衢州机场.geojson',
    // '/Cesium/NoFlyZone/泉州_晋江机场.geojson',
    // '/Cesium/NoFlyZone/日照_山字河机场.geojson',
    // '/Cesium/NoFlyZone/三明_沙县机场.geojson',
    // '/Cesium/NoFlyZone/三亚_凤凰机场.geojson',
    // '/Cesium/NoFlyZone/厦门_高崎机场.geojson',
    // '/Cesium/NoFlyZone/上海_虹桥机场.geojson',
    // '/Cesium/NoFlyZone/上海_浦东机场.geojson',
    // '/Cesium/NoFlyZone/上饶_三清山机场.geojson',
    // '/Cesium/NoFlyZone/深圳_宝安机场.geojson',
    // '/Cesium/NoFlyZone/神农架_红坪机场.geojson',
    // '/Cesium/NoFlyZone/沈阳_桃仙机场.geojson',
    // '/Cesium/NoFlyZone/十堰_武当山机场.geojson',
    // '/Cesium/NoFlyZone/石河子_花园机场.geojson',
    // '/Cesium/NoFlyZone/石家庄_正定机场.geojson',
    // '/Cesium/NoFlyZone/塔城机场.geojson',
    // '/Cesium/NoFlyZone/台州_路桥机场.geojson',
    // '/Cesium/NoFlyZone/太原_武宿机场.geojson',
    // '/Cesium/NoFlyZone/唐山_三女河机场.geojson',
    // '/Cesium/NoFlyZone/腾冲_驼峰机场.geojson',
    '/Cesium/NoFlyZone/天津_滨海机场.geojson',
    // '/Cesium/NoFlyZone/通化_三源浦机场.geojson',
    // '/Cesium/NoFlyZone/铜仁_凤凰机场.geojson',
    // '/Cesium/NoFlyZone/吐鲁番_交河机场.geojson',
    // '/Cesium/NoFlyZone/万州_五桥机场.geojson',
    // '/Cesium/NoFlyZone/潍坊机场.geojson',
    // '/Cesium/NoFlyZone/温州_龙湾机场.geojson',
    // '/Cesium/NoFlyZone/乌海机场.geojson',
    // '/Cesium/NoFlyZone/乌兰察布_集宁机场.geojson',
    // '/Cesium/NoFlyZone/乌兰浩特_依勒力特机场.geojson',
    // '/Cesium/NoFlyZone/乌鲁木齐_地窝堡机场.geojson',
    // '/Cesium/NoFlyZone/梧州机场.geojson',
    // '/Cesium/NoFlyZone/武汉_天河机场.geojson',
    // '/Cesium/NoFlyZone/西安_咸阳机场.geojson',
    // '/Cesium/NoFlyZone/西昌_青山机场.geojson',
    // '/Cesium/NoFlyZone/西宁_曹家堡机场.geojson',
    // '/Cesium/NoFlyZone/西双版纳_嘎洒机场.geojson',
    // '/Cesium/NoFlyZone/锡林浩特机场.geojson',
    // '/Cesium/NoFlyZone/襄阳_刘集机场.geojson',
    // '/Cesium/NoFlyZone/忻州_五台山机场.geojson',
    // '/Cesium/NoFlyZone/新源_那拉提机场.geojson',
    // '/Cesium/NoFlyZone/兴义_万峰林机场.geojson',
    // '/Cesium/NoFlyZone/徐州_观音机场.geojson',
    // '/Cesium/NoFlyZone/烟台_蓬莱机场.geojson',
    // '/Cesium/NoFlyZone/延吉_朝阳川机场.geojson',
    // '/Cesium/NoFlyZone/扬州_泰州机场.geojson',
    // '/Cesium/NoFlyZone/伊春_林都机场.geojson',
    // '/Cesium/NoFlyZone/伊宁机场.geojson',
    // '/Cesium/NoFlyZone/宜宾_菜坝机场.geojson',
    // '/Cesium/NoFlyZone/宜昌_三峡机场.geojson',
    // '/Cesium/NoFlyZone/宜春_明月山机场.geojson',
    // '/Cesium/NoFlyZone/义乌机场.geojson',
    // '/Cesium/NoFlyZone/银川_河东机场.geojson',
    // '/Cesium/NoFlyZone/营口_兰旗机场.geojson',
    // '/Cesium/NoFlyZone/永州_零陵机场.geojson',
    // '/Cesium/NoFlyZone/榆林_榆阳机场.geojson',
    // '/Cesium/NoFlyZone/玉树_巴塘机场.geojson',
    // '/Cesium/NoFlyZone/扎兰屯_成吉思汗机场.geojson',
    // '/Cesium/NoFlyZone/湛江机场.geojson',
    // '/Cesium/NoFlyZone/张家界_荷花机场.geojson',
    // '/Cesium/NoFlyZone/张掖_甘州机场.geojson',
    // '/Cesium/NoFlyZone/长春_龙嘉机场.geojson',
    // '/Cesium/NoFlyZone/长沙_黄花机场.geojson',
    // '/Cesium/NoFlyZone/郑州_新郑机场.geojson',
    // '/Cesium/NoFlyZone/中卫_沙坡头机场.geojson',
    // '/Cesium/NoFlyZone/重庆_江北机场.geojson',
    // '/Cesium/NoFlyZone/珠海_金湾机场.geojson',
    // '/Cesium/NoFlyZone/阿尔山_伊尔施机场.geojson',
    // '/Cesium/NoFlyZone/阿克苏_温宿机场.geojson',
    // '/Cesium/NoFlyZone/阿勒泰机场.geojson',
    // '/Cesium/NoFlyZone/安庆机场.geojson',
    // '/Cesium/NoFlyZone/鞍山_腾鳌机场.geojson',
    // '/Cesium/NoFlyZone/巴彦淖尔_天吉泰机场.geojson',
    // '/Cesium/NoFlyZone/白城_长安机场.geojson',
  ]
}
