import { message } from 'ant-design-vue'
import request, { IPage, IWorkspaceResponse } from '/@/api/http/request'
import axios, { CancelTokenSource } from 'axios'
const HTTP_PREFIX = '/media/api/v2'
export const CANCEL_TOKEN: Map<string, CancelTokenSource> = new Map()
// Get Media Files
export const getMediaFiles = async function (wid: string, pagination: IPage, requestParams: any): Promise<IWorkspaceResponse<any>> {
  let url = `${HTTP_PREFIX}/files/${wid}/page?page=${pagination.page}&page_size=${pagination.page_size}`
  if (requestParams && Object.keys(requestParams).length > 0) {
    Object.keys(requestParams).forEach(key => {
      const val = requestParams[key]

      if (Array.isArray(val)) {
        for (let i = 0; i < val.length; i++) {
          const v = val[i]
          url += `&${key}=${val[i]}`
        }
      } else {
        url += `&${key}=${val}`
      }
    })
  }
  const result = await request.get(url)
  return result.data
}
// Download Media File
export const downloadMediaFile = async function (workspaceId: string, fileId: string): Promise<any> {
  const url = `${HTTP_PREFIX}/files/${workspaceId}/file/${fileId}/url`
  console.log(url)
  const result = await request.get(url, { responseType: 'blob' })
  if (result.data.type === 'application/json') {
    const reader = new FileReader()
    reader.onload = function (e) {
      const text = reader.result as string
      const result = JSON.parse(text)
      message.error(result.message)
    }
    reader.readAsText(result.data, 'utf-8')
  } else {
    return result.data
  }
}
export const batchDownloadMediaFiles = async function (workspaceId: string, fileIds: string[]): Promise<any> {
  const url = `${HTTP_PREFIX}/files/${workspaceId}/file/download`
  const result = await request.post(url, fileIds, { responseType: 'blob' })
  if (result.data.type === 'application/json') {
    const reader = new FileReader()
    reader.onload = function (e) {
      const text = reader.result as string
      const result = JSON.parse(text)
      message.error(result.message)
    }
    reader.readAsText(result.data, 'utf-8')
  } else {
    return result.data
  }
}

export const saveDataSet = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `${HTTP_PREFIX}/files/${workspaceId}/file/saveDataSet`
  const result = await request.post(url, body)
  return result.data
}

export const getStorageContent = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/getStorageContent`
  const result = await request.get(url, body)
  return result.data
}

export const createFolder = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/createFolder`
  const result = await request.post(url, body)
  return result.data
}
export const updateFileName = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/updateFileName/${body.id}`
  const result = await request.put(url, body)
  return result.data
}

export const compressDownload = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/compressDownload`
  const result = await request.post(url, { data: body })
  return result.data
}

export const getImageDetails = async function (workspaceId: string, id:any): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/getById/${id}`
  const source = axios.CancelToken.source()
  CANCEL_TOKEN.set('getImageDetails', source)
  const result = await request.get(url, {
    cancelToken: source.token
  })
  return result?.data
}

export const deleteFile = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/deleteFile`
  const result = await request({
    method: 'delete',
    url: url,
    data: body
  })
  // const result = await request.delete(url, { params: body })
  return result.data
}

export const movePopupApi = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/moveFile`
  const result = await request.put(url, body)
  return result.data
}

// 上传zip
export const uploadZip = async function (workspaceId: string, body: {}): Promise<IWorkspaceResponse<any>> {
  const url = `/media/api/v2/files/${workspaceId}/uploadZip`
  const result = await request.post(url, body)
  return result.data
}
