import store from '/@/store'
import { getRoot } from '/@/root'
import { ELocalStorageKey, EDeviceTypeName } from '/@/types'
import { getDeviceBySn, getDeviceTopo } from '/@/api/manage'
import { message } from 'ant-design-vue'
import dockIcon from '/@/assets/icons/dock.png'
import landIcon from '/@/assets/icons/land.png'
import rcIcon from '/@/assets/icons/rc.png'
import recordIcon from '/@/assets/icons/record.png'
import droneIcon from '/@/assets/icons/drone.png'
import { GeojsonCoordinate } from '/@/types/map'
import { gcj02towgs84, wgs84togcj02 } from '/@/vendors/coordtransform'
import { preview } from 'vue3-preview-image'
import { CURRENT_CONFIG as config } from '/@/api/http/config'
import { useVueCesium } from 'vue-cesium'
import type { VcViewerProvider, VcReadyObject } from 'vue-cesium/es/utils/types'
import { reactive } from 'vue'

export function deviceTsaUpdate () {
  const root = getRoot()
  const AMap = root.$aMap
  // const $vc: VcViewerProvider = useVueCesium()
  const Cesium = root.$Cesium
  const viewer = root.$viewer

  const icons = new Map([
    [EDeviceTypeName.Aircraft, droneIcon],
    [EDeviceTypeName.Gateway, rcIcon],
    [EDeviceTypeName.Dock, dockIcon],
    [EDeviceTypeName.Land, landIcon],
    [EDeviceTypeName.Gb28281, recordIcon]
  ])
  const markers = store.state.markerInfo.coverMap
  const mapmarkers = store.state.markerMapInfo.coverMap
  const paths = store.state.markerInfo.pathMap

  let trackLine = null as any
  function getTrackLineInstance () {
    if (!trackLine) {
      trackLine = new AMap.Polyline({
        map: root.$map,
        // strokeColor: '#939393' // 线颜色
        strokeColor: '#00FA9A'
      })
    }
    return trackLine
  }

  function initIcon (type: number) {
    return new AMap.Icon({
      image: icons.get(type),
      imageSize: new AMap.Size(40, 40),
      size: new AMap.Size(40, 40)
    })
  }

  function initMarker (type: number, name: string, sn: string, lng?: number, lat?: number, height?: number, unStore?: boolean) {
    if (markers[sn]) {
      return
    }
    if (viewer === undefined || viewer.dataSourceDisplay === undefined) {
      return
    }
    if (lng === undefined || lat === undefined) {
      return
    }
    markers[sn] = viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(lng, lat, height),
      billboard: {
        id: sn,
        eyeOffset: EDeviceTypeName.Land === type ? new Cesium.Cartesian3(0.0, 0.0, 2.0) : EDeviceTypeName.Dock === type ? new Cesium.Cartesian3(0.0, 0.0, 1.0) : new Cesium.Cartesian3(0.0, 0.0, 0.0),
        image: icons.get(type), // default: undefined
        show: true, // default
        // pixelOffset: new Cesium.Cartesian2(0, 0), // default: (0, 0)
        // eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 0.0), // default
        // horizontalOrigin: Cesium.HorizontalOrigin.CENTER, // default
        // verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // default: CENTER
        scale: 1.5, // default: 1.0
        alignedAxis: Cesium.Cartesian3.clone(Cesium.Cartesian3.UNIT_Z),
        // alignedAxis: Cesium.Cartesian3.fromDegrees(0.0, 1.0, 0.0), // 设置对齐轴为z轴
        // color: Cesium.Color.LIME, // default: WHITE
        // rotation: Cesium.Math.toRadians(-90), // default: 0.0 以弧度指定旋转角度的数字
        // rotation: attitude_head? Cesium.Math.toRadians(attitude_head): 0,
        // alignedAxis: Cesium.Cartesian3.ZERO, // default
        width: 40, // default: undefined
        height: 40, // default: undefined
      },
      label: {
        text: name,
        font: '18px sans-serif', // 字体大小
        style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 字体样式
        fillColor: Cesium.Color.fromCssColorString('#000'), // 字体填充色
        outlineWidth: 2, // 字体外圈线宽度（同样也有颜色可设置）
        outlineColor: Cesium.Color.fromCssColorString('#fff'),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直位置
        pixelOffset: new Cesium.Cartesian2(0, 40), // 中心位置
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      },
      ellipse: {
        show: type === 3,
        fill: false,
        semiMinorAxis: 5000.0,
        semiMajorAxis: 5000.0,
        outlineColor: Cesium.Color.YELLOW,
        outlineWidth: 5.0,
        outline: true,
      },
    })
    if (!unStore) {
      const coverMarkerInfo = store.state.coverMarkerInfo
      coverMarkerInfo.push({
        type: type,
        name: name,
        sn: sn,
        lng: lng,
        lat: lat,
        height: height
      })
      store.commit('SET_COVER_MARKER_INFO', coverMarkerInfo)
    }
  }

  function initMapMarker (iconurl: string, name: string, sn: string, lng?: number, lat?: number, error?: number) {
    if (mapmarkers[sn]) {
      return
    }
    if (viewer === undefined) {
      return
    }
    mapmarkers[sn] = viewer.entities.add({
      position: Cesium.Cartesian3.fromDegrees(lng, lat),
      billboard: {
        id: sn,
        eyeOffset: new Cesium.Cartesian3(0.0, 0.0, 1.0),
        image: config.imageResultURL + iconurl + config.imageIconParam,
        show: true, // default
        scale: 1.5, // default: 1.0
        alignedAxis: Cesium.Cartesian3.clone(Cesium.Cartesian3.UNIT_Z),
        width: 40, // default: undefined
        height: 40, // default: undefined
      },
      label: {
        text: error === 1 ? name : '',
        font: '14px sans-serif', // 字体大小
        style: Cesium.LabelStyle.FILL_AND_OUTLINE, // 字体样式
        fillColor: Cesium.Color.fromCssColorString('#ff88ff'), // 字体填充色
        outlineWidth: 1, // 字体外圈线宽度（同样也有颜色可设置）
        outlineColor: Cesium.Color.fromCssColorString('#ffffff'),
        verticalOrigin: Cesium.VerticalOrigin.BOTTOM, // 垂直位置
        pixelOffset: new Cesium.Cartesian2(0, 40), // 中心位置
        disableDepthTestDistance: Number.POSITIVE_INFINITY,
      },
    })
    // const transResult = wgs84togcj02(
    //   lng,
    //   lat
    // ) as GeojsonCoordinate
    // mapmarkers[sn] = new AMap.Marker({
    //   position: new AMap.LngLat(transResult[0], transResult[1]),
    //   icon: config.imageResultURL + iconurl + config.imageIconParam,
    //   title: name,
    //   label: {
    //     content: error === 1 ? name : '',
    //     direction: 'bottom'
    //   },
    //   anchor: 'top-center',
    //   offset: [0, -20],
    // })
    // root.$map.add(mapmarkers[sn])
    // 点击
    // mapmarkers[sn].on('click', function (e: any) {
    //   preview(iconurl)
    // })
  }

  function removeMapMarker (sn: string) {
    if (!mapmarkers[sn]) {
      return
    }
    viewer.entities.remove(markers[sn])
    delete mapmarkers[sn]
  }

  function removeMarker (sn: string) {
    if (!markers[sn]) {
      return
    }
    viewer.entities.remove(markers[sn])
    // getTrackLineInstance().setPath([])
    delete markers[sn]
    delete paths[sn]
  }

  function addMarker (sn: string, lng?: number, lat?: number, height?: number, record = false) {
    if (record) {
      initMarker(0, '飞行记录', sn, lng, lat, height)
    } else {
      getDeviceBySn(localStorage.getItem(ELocalStorageKey.WorkspaceId)!, sn).then(data => {
        if (data.code !== 0) {
          message.error(data.message)
          return
        }
        initMarker(data.data.domain, data.data.nickname, sn, lng, lat, height)
      })
    }
  }

  function moveTo (sn: string, lng: number, lat: number, height?: number, attitude_head?: number, record = false) {
    let marker = markers[sn]
    if (!marker) {
      addMarker(sn, lng, lat, height, record)
      marker = markers[sn]
      return
    }
    marker.position = Cesium.Cartesian3.fromDegrees(lng, lat, height)
    if (attitude_head) {
      marker.billboard.rotation = Cesium.Math.toRadians(-attitude_head)
    }
  }

  function redrawDock (isInitMarker: boolean) {
    store.state.coverMarkerInfo.forEach(item => {
      if (item.lng && item.lat) {
        removeMarker(item.sn)
        if (isInitMarker) {
          initMarker(item.type, item.name, item.sn, item.lng, item.lat, item.height, true)
        }
      }
    })
  }

  const onlineDevicesData = reactive({
    data: [] as any[],
  })
  const workspaceId = localStorage.getItem(ELocalStorageKey.WorkspaceId)!

  function getDeviceName (sn: string) {
    if (onlineDevicesData.data.length === 0) {
      getOnlineTopo()
    }
    const item = onlineDevicesData.data.find(item => item.device_sn === sn)
    if (!item) {
      return ''
    }
    return item.nickname
  }

  function getOnlineTopo () {
    onlineDevicesData.data = []
    getDeviceTopo(workspaceId).then((res) => {
      if (res.code !== 0) {
        return
      }
      onlineDevicesData.data = res.data
    })
  }

  function removeAllMarker () {
    if (viewer.entities && viewer.entities?.length > 0) {
      viewer.entities.removeAll()
    }
    store.commit('SET_MARKERINFO_COVERMAP', {})
    store.commit('SET_COVER_MAP', {})
  }

  return {
    marker: markers,
    initMarker,
    initMapMarker,
    removeMarker,
    removeMapMarker,
    removeAllMarker,
    redrawDock,
    moveTo
  }
}
